import React, { useState } from "react";
import { SoundRecorder } from "../../../Components/SoundRecorder/SoundRecorder";
import { AudioRecognitionHistoryList } from "./AudioRecognitionHistoryList";
import { Button, Flex, Select } from "antd";

import './AudioRecognition.scss'
import { IconMicrophone } from "../../../Components/Icons/IconMicrophone";

export const AudioRecognition: React.FC = ()=>{
  // const [ language, setLanguage ] = useState<string>('ru-ru')
  const [state, setState] = useState<string>('idle')

  return <Flex vertical style={{width:"100%"}}>
    <Flex vertical className="audioRecognition">
      <Flex className="audioRecognitionLabel">
        Выберите язык, нажмите кнопку запись и говорите в микрофон

      </Flex>
      <Flex className="audioRecognitionProperties" style={{width: '100%'}}>
        {/* <Select
        defaultValue={'ru-ru'} 
        value={language}
        onChange={(value)=>{setLanguage(value)}}
        style={{width: '300px'}}>
          <Select.Option value='ru-ru'>Русский</Select.Option>
        </Select> */}
        <Button type="primary" 
        icon={<IconMicrophone className="primary"/>}
        onClick={()=>setState('record')}>
          Запись
          </Button>
      </Flex>

    </Flex>
    <SoundRecorder state={state} setState={setState} />
  </Flex>
}
