import { Select } from "antd"
import React from "react"
import { useGetNeurotesOptrionAliasesQuery, useGetNeurotesTypesQuery } from "../../store/neuronetApiSlice"

type PropsType = {
  value: any,
  onChange: (value: string)=>void
  disabled?: boolean
}

const NeuronetOptionAliasSelector: React.FC<PropsType> = (props)=>{

    const {data, isLoading } = useGetNeurotesOptrionAliasesQuery({})
    
    if(isLoading){
        return <>Loading...</>
    }
    if(!data) {
        return <>No data</>
    }
    const keys = Object.keys(data)

  return <Select defaultValue={props.value} value={props.value} onChange={props.onChange} allowClear={true}
      // style={{width: '200px'}}
      disabled={props.disabled}>
        {keys.map(k=>
            <Select.Option value={data[k]}>{k}</Select.Option>
        )}
      </Select>
}

export default NeuronetOptionAliasSelector;