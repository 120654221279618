import { Button, Checkbox, CheckboxChangeEvent } from "antd";
import React, { useState } from "react";

import "./DeleteDialog.scss"
import { IconCross } from "../Icons/IconCross";
import { getDeleteImmediate, setDeleteImmediate } from "../../store/commonSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";

type PropsType = {
  onDelete: ()=>void
  onClose: ()=>void
}

export const DeleteDialog: React.FC<PropsType> = ({ onClose, onDelete }) => {
  const dispatch = useAppDispatch()
  const savedNotAsk = useSelector(getDeleteImmediate)

  const [notAsk, setNotAsk] = useState<boolean>(savedNotAsk==='true')

  if (savedNotAsk==='true') {
    onDelete()
    return <></>
  }

  const notAskChanged =(e: CheckboxChangeEvent)=>{
    dispatch(setDeleteImmediate(e.target.checked))
  }

  return <div className="deleteDialogContainer">
    <div  className="deleteDialog">
    <div className="deleteDialogLabelContainer">
      <div className="deleteDialogLabel">
        Удалить запись?
      </div>
      <div className="deleteDialogLabelClose"  onClick={onClose}>
        <IconCross />
      </div>
      </div>
      <div className="deleteDialogText">
      Вы действительно хотите удалить файл? <br/>
      После удаления это действие нельзя будет отменить.<br/>
      <br/>
      <span>
        <Checkbox value={notAsk} onChange={notAskChanged} title="Больше не спрашивать">Больше не спрашивать</Checkbox>
      </span>

      </div>
      <div className="deleteDialogFooter">
        <Button onClick={onClose}>
          Отменить
        </Button>
        <Button onClick={onDelete}>
          Удалить
        </Button>
      </div>
    </div>
  </div>
}