import { io, Socket } from 'socket.io-client'
import { RootState } from './store'
import { apiSlice } from '../api/apiSlice'
import { showNotification } from './notificationSlice'
import { Mutex } from 'async-mutex'
import { setVizardDownloading } from './vizardSlice'
import { VizardDownloadsType } from '../types/Vizard.type'

export const socketMiddleware =
  (socket: Socket) => (params: any) => (next: any) => async (action: any) => {
    const { dispatch, getState } = params
    const { type } = action
      console.log(socket.listeners('message'))
      switch (type) {
        case 'socket/connect':
          
          if(!socket.hasListeners('message')){
            // console.log('WS socketMiddleware connect')
            // socket.on('open', () => {
            //   console.log('ws open')
            // })

            socket.on('message', data => {
              if (data.action) {
                switch (data.action) {
                  case 'errorMessage':
                    dispatch(
                      showNotification({
                        type: 'error',
                        description: data.message,
                        message: 'Ошибка!'
                      })
                    )
                    break
                  case 'infoMessage':
                    dispatch(
                      showNotification({
                        type: 'info',
                        description: data.message,
                        message: data.subject
                      })
                    )
                    break
                  case 'messageUpdated':
                    dispatch(apiSlice.util.invalidateTags(['ChatMessages']))
                    break
                  case 'chatUpdated':
                    dispatch(apiSlice.util.invalidateTags(['Chats']))
                    break
                  case 'supportRequestUpdated':
                    dispatch(apiSlice.util.invalidateTags(['SupportRequests', 'SupportRequest']))
                    break
                  case 'vizard_updated':
                    dispatch(apiSlice.util.invalidateTags(['Vizards', {type: 'Vizard', id: data.id}]))
                  break
                  case 'vizard_downloads':
                    const id = data.id
                    const downloading_data: VizardDownloadsType[] = data.data
                    dispatch(setVizardDownloading({id: id, data: downloading_data }))
                    dispatch(apiSlice.util.invalidateTags(['Vizards', {type: 'Vizard', id: data.id}]))
                  break;
                  case 'midjourney_updated':
                    dispatch(apiSlice.util.invalidateTags(['MidjourneyItems', {type: 'MidjourneyItem', id: data.id}, 'Chats']))
                  break
                  case 'did_updated':
                    dispatch(apiSlice.util.invalidateTags(['DidProjects']))
                  break;
                }
              }
            })
            socket.on('close', () => {
              socket.offAny()
              
              // console.log('ws closed')
              
            })
          }
          const token = (getState() as RootState).auth.token
          socket.auth = {
            Authorization: `Bearer ${token}`
          }
          socket.connect()
          break
  
        case 'socket/disconnect':
          console.log('WS Disconnect')
          socket.disconnect()
          
          break
  
        default:
          break
      }


    return next(action)
  }
