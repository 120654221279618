import React, { useState } from "react";

import './MidjourneyProject.scss'

import { MidjourneyProjectItem } from "./MidjourneyProjectItem";
import { MidjourneyItemType } from "../../../types/Midjourney.type";
import { Popover } from "antd";


type PropsType = {
  // id?: string
  item?: MidjourneyItemType
}

export const MidjourneyProject: React.FC<PropsType> = ({ item })=>{

  const [showFullHeader, setShowFullHeader]= useState<boolean>(false)

  const toggleShowFullHeader = ()=>{
    setShowFullHeader(!showFullHeader)
  }
  // const { data, isLoading }  = useGetMidjourneyProjectQuery({id}, {skip: !id})
  
  // if (isLoading) return <>Loading</>
  // if (!data) return <>No data</>

  if(item){
    return <div className="mjProject">
      <div className={"mjProjectHeader" + (showFullHeader ? ' fullHeader' : ' clampHeader')}>
        <div className="mjProjectHeaderText" onClick={toggleShowFullHeader}>
            {item?.text}
        </div>
        <div className="mjProjectHeaderDate">{item && time_ago(item?.created_at)}</div>
      </div>
      <div className="mjProjectCards">
        <MidjourneyProjectItem item={item} />
      </div>
    </div>
  }
  return <></>
}


function time_ago(time: any) {

  switch (typeof time) {
    case 'number':
      break;
    case 'string':
      time = +new Date(time);
      break;
    case 'object':
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  var time_formats:[number, string, number|string][] = [
    [60, 'секунд', 1], // 60
    [120, '1 минуту назад', 'через 1 минуту'], // 60*2
    [3600, 'минут', 60], // 60*60, 60
    [7200, '1 час назад', 'через 1 час'], // 60*60*2
    [86400, 'часа', 3600], // 60*60*24, 60*60
    [172800, 'Вчера', 'Завтра'], // 60*60*24*2
    [604800, 'дней', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'на прошлой неделе', 'на следующей неделе'], // 60*60*24*7*4*2
    [2419200, 'недель', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'В прошлом месяце', 'В следующем месяце'], // 60*60*24*7*4*2
    [29030400, 'месяцев', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'В прошлом году', 'В следующем году'], // 60*60*24*7*4*12*2
    [2903040000, 'лет', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  var seconds:number = (+new Date() - time) / 1000,
    token = 'назад',
    list_choice = 1;

  if (seconds == 0) {
    return 'только что'
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    list_choice = 2;
  }
  var i = 0,
    format;
  // eslint-disable-next-line no-cond-assign
  while (format = time_formats[i++])
    if (seconds < format[0]) {
      if (typeof format[2] == 'string')
        return format[list_choice];
      else
        return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
    }
  return time;
}