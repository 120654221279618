import React from "react";
import { MidjourneyCreateProps } from "./MidjourneyCreateProps";
import { MidjourneyProject } from "./MidjourneyProject";
import { useParams } from "react-router-dom";
import { useGetMidjourneyProjectsListQuery } from "../../../store/midjourneyApiSlice";

import { MidjourneyItemType } from "../../../types/Midjourney.type";
import "./LkMidjourneyPage.scss"


export const LkMidjourneyPage: React.FC = () => {

  const params = useParams();

  const { data, isLoading } = useGetMidjourneyProjectsListQuery({page: 1, limit: 5}) 

  return <div className="lkMidjourneyPage">
    <div className="lkMidjourneyPageAlert">
      Данный раздел предназначен для создания сложных, детализированных и высококачественных изображений. <br/>
      Время обработки одного запроса составляет от 2 до 5 минут. Возможно создание контента, связанного с политикой.<br/>
      Рекомендуется использовать данный раздел для выполнения сложных и нестандартных задач.
    </div>
    <div className="lkMidjourneyPageContainer">
      <div className="lkMidjourneyPagePropsContainer" style={{width: "30%"}}>
        <MidjourneyCreateProps id={params.chat_id}  />
      </div>
      <div className="lkMidjourneyPageProjectContainer" >
        {data && data.map((item: MidjourneyItemType)=>{
          return <MidjourneyProject item={item} />
        })}
      </div>
    </div>
  </div>
}