import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconStop: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)
    
    return <svg className={classNames.join(' ')}  width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.20346 0.75H3.80177C1.88982 0.75 0.75 1.88925 0.75 3.80025V8.1945C0.75 10.1108 1.88982 11.25 3.80177 11.25H8.19821C10.1102 11.25 11.25 10.1108 11.25 8.19975V3.80025C11.2552 1.88925 10.1154 0.75 8.20346 0.75Z" fill="#7479F6"/>
    </svg>
    
//       return <svg className={classNames.join(' ')} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M10.8767 3.53833L1.64833 9.08C1.2925 9.29583 0.814167 9.08 0.7675 8.66583C0.755833 8.555 0.75 8.44417 0.75 8.33333V3.66667C0.75 1.91667 1.91667 0.75 3.66667 0.75H8.33333C10.0833 0.75 10.8008 1.64833 11.1392 2.87917C11.2092 3.13583 11.0983 3.40417 10.8767 3.53833Z" fill="#7479F6"/>
// <path d="M11.25 5.46917V8.33333C11.25 10.0833 10.0833 11.25 8.33333 11.25H3.66667C3.13 11.25 2.6225 11.1042 2.19083 10.8475C1.8175 10.6317 1.84083 10.0833 2.20833 9.86167L10.3633 4.9675C10.7542 4.73417 11.25 5.01417 11.25 5.46917Z" fill="#7479F6"/>
// </svg>

}

