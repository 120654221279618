// import { Popconfirm, Table, Tooltip } from "antd";
import React from "react";
import { transformGetCreateChatsOptionsQueryData, useGetCreateChatsOptionsQuery } from "../../../store/webchatApiSlice";

import { useParams } from "react-router-dom";
import { LkChatCard } from "../../../Components/lk/LkChatCard";
import { Card, Flex } from "antd";
import { LastTextGenerations } from "./LastTextGenerations";
import { LastImageGenerations } from "./LastImageGenerations";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import "./LkDashboard.scss"
import { HistoryTextGenerations } from "../history/HistoryTextGenerations";
import { HistoryImageGenerations } from "../history/HistoryImageGenerations";
import { HistoryAudioGenerations } from "../history/HistoryAudioGenerations";
import { HistoryVizardGenerations } from "../history/HistoryVizardGenerations";
import { HistoryDidGenerations } from "../history/HistoryDidGenerations";

export const LkDashboard: React.FC = () => {
  const params = useParams();
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );

  const { data: createChatData } = useGetCreateChatsOptionsQuery({
    output_type: params.type,
  });

   const dataImages = transformGetCreateChatsOptionsQueryData(createChatData, ["image"]);
  const dataText = transformGetCreateChatsOptionsQueryData(createChatData, ["text"]);
  const dataAudio = transformGetCreateChatsOptionsQueryData(createChatData, ["audio", 'audio_recognition']);
  const dataVideo = transformGetCreateChatsOptionsQueryData(createChatData, ["video"]);

  return (
    <div className={"lkDashBoard " + currentAlgorithm}>
      <Flex style={{justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap'}}>
        
          <Flex style={{margin: '12px', width: '100%'}}>
            {dataImages && (
              <Card className="lkCardsContainer" title="Генерация изображений">
                <Flex style={{gap: '24px'}} wrap={true}>
                  {dataImages.map((item: any) => {
                    return (
                      <LkChatCard key={item.key} data={item} parent_id={null} />
                    );
                  })}
                </Flex>
              </Card>
            )}
          </Flex>

          <Flex style={{margin: '12px', width: '100%'}}>
            {dataAudio && dataAudio.length>0 &&(
              <Card className="lkCardsContainer" title="Генерация аудио">
                <Flex style={{gap: '24px'}} wrap={true}>
                  {dataAudio.map((item: any) => {
                    return (
                      <LkChatCard key={item.key} data={item} parent_id={null} />
                    );
                  })}
                </Flex>
              </Card>
            )}
          </Flex>

          <Flex style={{margin: '12px', width: '100%'}}>
            {dataVideo && dataVideo.length>0 &&(
              <Card className="lkCardsContainer" title="Генерация видео">
                <Flex style={{gap: '24px', width: '100%'}} wrap={true}>
                  {dataVideo.map((item: any) => {
                    return (
                      <LkChatCard key={item.key} data={item} parent_id={null} />
                    );
                  })}
                </Flex>
              </Card>
            )}
          </Flex>



        <Flex style={{margin: '12px', width: '100%'}}>
          {dataText && (
            <Card className="lkCardsContainer" title="Работа с текстом">
              <Flex style={{gap: '24px', width: '100%' }} wrap={true}>
                {dataText.map((item: any) => {
                  return (
                    <LkChatCard key={item.key} data={item} parent_id={null} />
                  );
                })}
              </Flex>
            </Card>
          )}
        </Flex>
      
      </Flex>



      <Flex gap={24} vertical>
        <Card className="lkCardsContainer" style={{margin: '12px'}}>
          <HistoryTextGenerations showFull={false} />
        </Card>
        <Card className="lkCardsContainer" style={{margin: '12px'}}>
          <HistoryImageGenerations showFull={false} />
        </Card>
        <Card className="lkCardsContainer" style={{margin: '12px'}}>
          <HistoryAudioGenerations showFull={false} />
        </Card>
        <Card className="lkCardsContainer"  style={{margin: '12px'}}>
          <HistoryVizardGenerations showFull={false} />
        </Card>
        <Card className="lkCardsContainer"  style={{margin: '12px'}}>
          <HistoryDidGenerations showFull={false} />
        </Card>

      </Flex>
    </div>
  );
};
