import React, { useEffect, useRef, useState } from "react";

import "./VideoPlayerModal.scss";
import { FullscreenOutlined, PauseCircleFilled, PlayCircleFilled, StepForwardOutlined } from "@ant-design/icons";
import { IconVolumeHigh } from "../Icons/IconVolumeHigh";

import { useAppDispatch, useAppSelector } from "../../store/store";
import { closeVideoPlayer, openVideoPlayer } from "../../store/videoPlayerSlice";

export const VideoPlayerModal: React.FC = () => {
  const dispatch = useAppDispatch()

  
  const playerRef = useRef<any>();
  const [ state, setState] = useState<string>('stopped')
  const [ currentTime, setCurrentTime ] = useState(0)
  const [ percent, setPercent ] = useState(0)
  const [ duration, setDuration ] = useState(0)

  const url = useAppSelector((state)=>state.videoPlayer.url)
  const type = useAppSelector((state)=>state.videoPlayer.type)
  const item = useAppSelector((state)=>state.videoPlayer.item)
  const openModal = useAppSelector((state)=>state.videoPlayer.open)

  useEffect(()=>{
    if(!playerRef.current) return
    if(state === 'play') {
      playerRef.current.addEventListener('timeupdate', timeupdate)
      playerRef.current.addEventListener('ended', playend)
      playerRef.current.play()
    }    
  }, [state])

  const timeupdate = (e: any) => {
    setCurrentTime(e.target.currentTime);
    setPercent(Math.round(e.target.currentTime/e.target.duration * 100));
    setDuration(e.target.duration)
  }
  const handleCloseModal = () => {
    setState('stopped')
    playerRef.current.currentTime = 0;
    setCurrentTime(0);
    dispatch(closeVideoPlayer({}));
  };

  const playend = (e: any) => {
    // onStop && onStop()
    setState('stopped')
  }
  const hanldeClick = (e: any)=>{
    e.preventDefault()
    e.stopPropagation()
  }

  const playerStartStop = () => {
    if(!playerRef.current) return
    if(!playerRef.current.paused){
      playerRef.current.pause()
      setState('pause')
    } else {
      playerRef.current.play()
      setState('play')
    }
  }
  const playerRewind = (e: any) => {
    let posx = 0;
    if (e.pageX || e.pageY)
    {
        posx = e.pageX;
    }
    else if (e.clientX || e.clientY)
    {
        posx = e.clientX;
    }
    const offsetx = posx - getOffsetX(e.target)
    const pc = offsetx / e.target.clientWidth * 100

    playerRef.current.currentTime = playerRef.current.duration/100 * pc
  }

  const getTimeStr = (time: string) => {
    const sec_num = parseInt(time, 10); // don't forget the second param
    let hours: number|string   = Math.floor(sec_num / 3600);
    let minutes: number|string = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds: number|string = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    if(hours !== '00') return hours+':'+minutes+':'+seconds;
    return minutes+':'+seconds;
  }

  const requestFullScreen = () => {
    if(!playerRef.current) return
    
    if (playerRef.current.fullscreenElement) {
        playerRef.current.exitFullscreen();
    } else if (playerRef.current.requestFullscreen) {
      playerRef.current.requestFullscreen();
    } else if (playerRef.current.mozRequestFullScreen) {
      playerRef.current.mozRequestFullScreen();
    } else if (playerRef.current.webkitRequestFullscreen) {
      playerRef.current.webkitRequestFullscreen();
    } else if (playerRef.current.msRequestFullscreen) { 
      playerRef.current.msRequestFullscreen();
    }
  }

  const getOffsetX = (el: any): number => {
    if(el.offsetParent) return el.offsetLeft + getOffsetX(el.offsetParent)
      return el.offsetLeft
  }

  if (type === 'did') {
    return null
  }
    
  return (<>
    {openModal && url && (
      <div className="videoPlayer">
        <div className="videoPlayerModal" onClick={handleCloseModal}>
          <div className="videoPlayerModalBody" onClick={hanldeClick}>
            <div className="videoPlayerModalBodyPlayer">
              <video
                ref={playerRef}
                src={url}
                controls={false}
              />
            </div>
            <div className="videoPlayerModalBodyControlsContainer">
              <div className="videoPlayerModalBodyControls">
                <div onClick={playerStartStop}>
                { (state !=='play' && state !=='pause') &&
                  <PlayCircleFilled  style={{ fontSize: '32px'}} />
                }
                { state==='pause' && 
                  <PlayCircleFilled  style={{ fontSize: '32px'}} />
                }
                { state ==='play' && 
                  <PauseCircleFilled style={{ fontSize: '32px'}} />
                }
                </div>
                <div>{getTimeStr(''+Math.round(currentTime))}</div>
                <div className="videoPlayerModalBodyControlsProgress" onClick={playerRewind}>
                  <div className="videoPlayerModalBodyControlsProgressBar" style={{width: `${percent}%`}}></div>
                </div>
                <div>{getTimeStr(''+Math.round(duration))}</div>
              
                {/* <div><StepForwardOutlined onClick={} style={{ fontSize: '32px'}}/></div> */}
                {/* <div><IconVolumeHigh /></div> */}
                <div><FullscreenOutlined onClick={requestFullScreen} style={{ fontSize: '32px'}}/></div>
              </div>
            </div>
          </div>
        </div>
        </div>
      )}
  </>);
};
