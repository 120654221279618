import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "./store"

const commonSlice = createSlice({
  name: 'common',
  initialState: () => {
    const init = {
      deleteImmediate: localStorage.getItem('deleteImmediate'),
  }
  return init
  },
  reducers: {
    setDeleteImmediate: (state, action)=>{
      state.deleteImmediate = action.payload
      localStorage.setItem('deleteImmediate', action.payload)
    }
  }
})

export const { setDeleteImmediate } = commonSlice.actions

export default commonSlice.reducer

export const getDeleteImmediate = (state: RootState) => state.common.deleteImmediate