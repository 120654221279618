import {
  Button,
  DatePicker,
  Pagination,
  Popconfirm,
  Switch,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import "./AdminHistoryDidGenerations.scss";
import { Loader } from "../../../Components/common/Loader";
import { Empty } from "../../../Components/common/Empty";

import { useGetDidProjectsListQuery } from "../../../store/didApiSlice";
import { DidVideoCard } from "../../lk/did/DidVideoCard";
import { DidVideoPlayer } from "../../lk/did/DidVideoPlayer";
import UserSelector from "../../../Components/common/UserSelector";

const { RangePicker } = DatePicker;

type PropsType = {
  showFull: boolean;
};

export const AdminHistoryDidGenerations: React.FC<PropsType> = ({ showFull }) => {
  const navigate = useNavigate();
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const [favorites, setFavorites] = useState<boolean>(false);
  const [searchUser, setSearchUser] = useState<string|undefined>();

  const [range, setRange] = useState<any>();
  // const [deleteChatMutation] = useDeleteChatMutation();
  // const [toggleFavoriteMutation] = useToggleFavoriteMutation();
  const [pagination, setPagination] = useState<any>({
    total: 1,
    pageSize: showFull ? 24 : 12,
    pageSizeOptions: [12, 24, 48, 96],
    current: 1,
  });
  const { data, isLoading, isError } = useGetDidProjectsListQuery({
    page: pagination.current,
    limit: pagination.pageSize,
    from: (range ? range[0]?.toDate().getTime() : undefined),
    to: (range ? range[1]?.toDate().getTime() : undefined),
    user_id: searchUser,
    favorites: favorites ? true : undefined,
  });

  // useEffect(() => {
  //   setPagination({ ...pagination, pageSize: showFull ? 10 : 5 });
  // }, [showFull]);
  useEffect(() => {
    if (data) {
      setPagination({ ...pagination, total: data[1] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const setDateRange = (e: any) => {
    setRange(e);
  };
  if (!data) return <Loader />;

  return (
    <div className={"adminHistoryDidGenerations " + (showFull ? "showFull" : "")}>
      {!showFull && (
        <div className="historyDidGenerationsHeader">
          <span>Генерция видео с аватаром</span>
          <span>
            <Button
              disabled={!isLoading && data && data[1] === 0}
              onClick={() => navigate("/admin/history/did")}>
              Смотреть все генерации
            </Button>
          </span>
        </div>
      )}
      {showFull && (
        <div className={"historyDidGenerationsFilters " + currentAlgorithm}>
          <div className="historyDidGenerationsFiltersDatePicker">
            <span>Период</span>
            <span>
              <RangePicker value={range} onChange={setDateRange} />
            </span>
          </div>
          <div className="historyImageGenerationsFiltersSelector">
          <span>
              Пользователи
              </span>
              <span>
            <UserSelector value={searchUser} onChange={setSearchUser} />
              </span>
          </div>
          <div className="historyDidGenerationsFiltersCheckboxes">
            <Switch
              checked={favorites}
              onChange={() => setFavorites(!favorites)}
            />
            <span>Избранное</span>
          </div>
        </div>
      )}
      {!isLoading && data && data[1] > 0 && (
        <>
          {!isLoading && data && data[1] > 0 && (
            <>
              <div className={"didGenerations"}>
                <div className={"didGenerationsCards"}>
                  {data &&
                    data[0] &&
                    data[0].map((item: any) => {
                      return (
                        <DidVideoCard mode="admin" key={item.id} item={item} />
                      );
                    })}
                </div>
              </div>
              <Pagination
                onChange={(page: number, pageSize: number) => {
                  console.log(page, pageSize)
                  setPagination({ ...pagination, current: page, pageSize });
                }}
                align="end"
                defaultCurrent={pagination.page}
                pageSize={pagination.pageSize}
                total={data[1]}
                pageSizeOptions={pagination.pageSizeOptions}
              />
            </>
          )}
        </>
      )}
      {!isLoading && data && data[1] === 0 && <Empty />}
      <DidVideoPlayer />
    </div>
  );
};
