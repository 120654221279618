import { Button, DatePicker, Flex, Pagination, Popconfirm, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  useDeleteChatMutation,
  useGetChatsQuery,
  useToggleFavoriteMutation,
} from "../../../store/webchatApiSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import { IconStar } from "../../../Components/Icons/IconStar";
import { IconCross } from "../../../Components/Icons/IconCross";
import "./DidGenerations.scss";
import { Loader } from "../../../Components/common/Loader";
import { IconStarSelected } from "../../../Components/Icons/IconStarSelected";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { NeuronetsSelector } from "../../../Components/common/NeuronetsSelector";
import { Empty } from "../../../Components/common/Empty";
import { DidVideoCard } from "./DidVideoCard";
import { useDeleteDidItemMutation, useGetDidProjectsListQuery, useToggleDidFavoriteMutation } from "../../../store/didApiSlice";
const { RangePicker } = DatePicker;


export const DidGenerations: React.FC = () => {
  const navigate = useNavigate();
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const [pagination, setPagination] = useState<any>({
    total: 1,
    pageSize: 12,
    pageSizeOptions: [12, 24, 48, 96],
    current: 1,
  });
  const { data, isLoading, isError } = useGetDidProjectsListQuery({
    page: pagination.current,
    limit: pagination.pageSize
  });

  useEffect(() => {
    if (data) {
      setPagination({ ...pagination, total: data[1] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  
  if (!data) return <Loader />;
  if (isLoading) return <Loader />
  if(isError) return <>error</>
  const [messages, count] = data?data:[[],0]
  
  return (
    <div className={"didGenerations"}>
      
      {!isLoading && data && data[1]>0 && <>
      <div className={"didGenerationsCards"}>
        {messages &&
          messages.map((item: any) => {
            return <DidVideoCard mode='user' key={item.id} item={item} />
        })}
      </div>
        <Pagination onChange={(page: number, pageSize: number)=>{
          setPagination({...pagination,current: page, pageSize})
        }} align="end" 
          defaultCurrent={pagination.page} 
          pageSize={pagination.pageSize} 
          total={count} 
          pageSizeOptions={pagination.pageSizeOptions}/>
      </>
      }
         { !isLoading && data && data[1]===0 &&
          <Empty />
        }
    </div>
  );
};
