import React, { useEffect} from "react";
import { ConfigProvider, theme } from "antd";
import { Col, Flex, Layout, Row, Space, Spin } from "antd";
import { useDispatch } from "react-redux";
import { Button, Form, Input } from "antd";
import {
  useLoginByTgUserMutation,
  useLoginMutation,
} from "../../store/authApiSlice";
import { setToken } from "../../store/authSlice";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../store/notificationSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";

import "./Login.scss";
import { MaskedInput } from "antd-mask-input";
import { setCurrentUser } from "../../store/userSlice";
import { apiSlice } from "../../api/apiSlice";
import { UserRoleEnum } from "../../types/UserRole.enum";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export type FieldType = {
  phone_number?: string;
  password?: string;
};
export interface TelegramUser {
  id: number;
  first_name: string;
  username: string;
  photo_url: string;
  auth_date: number;
  hash: string;
}
declare global {
  interface Window {
    TelegramLoginWidget: {
      dataOnauth: (user: TelegramUser) => void;
    };
  }
}
const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ loginTG ] =useLoginByTgUserMutation();
  const [ login, { isLoading }] = useLoginMutation();

  const dataOnauth = async (user: any) => {
    const userData: any = await loginTG(user);
    if (userData?.error) {
      dispatch(
        showNotification({
          message: userData.error?.data?.error,
          description: "",
          type: "error",
        })
      );
    } else {
      if (userData.data.jwt) {
        dispatch(apiSlice.util.resetApiState());
        dispatch(setToken(userData.data.jwt));
        dispatch(setCurrentUser({ user: userData.data.user }));
        redirectOnAuth(userData.data);
      }
    }
  };

  window.TelegramLoginWidget = {
    dataOnauth: (user: TelegramUser) => dataOnauth(user),
  };
  useEffect(() => {
    const botName = process.env.REACT_APP_BOT_NAME;
    if (!botName) return;
    let tglogin = document.getElementById("tglogin");
    let script = document.createElement(`script`);
    script.src = "https://telegram.org/js/telegram-widget.js?22";
    const buttonSize = "large";

    script.setAttribute("data-telegram-login", botName);
    script.setAttribute("data-size", buttonSize);
    script.setAttribute("data-request-access", "true");
    script.setAttribute("data-userpic", "true");
    script.setAttribute("data-onauth", "TelegramLoginWidget.dataOnauth(user)");
    script.async = true;
    tglogin?.appendChild(script);
  });

  const redirectOnAuth = (userData: any) => {
    if (userData) {
      if (userData.user.role === UserRoleEnum.SuperAdmin) {
        navigate("/admin/users");
      } else if (userData.user.role === UserRoleEnum.Admin) {
        navigate("/admin/users");
      } else if (userData.user.role === UserRoleEnum.Member) {
        navigate("/lk");
      }
    }
  };

  const onFinish = async (values: any) => {
    try {
      const userData = await login({
        phone_number: values.phone_number,
        password: values.password,
      }).unwrap();
      if (userData.jwt) {
        dispatch(apiSlice.util.resetApiState());
        dispatch(setToken(userData.jwt));
        dispatch(setCurrentUser({ user: userData.user }));
        redirectOnAuth(userData);
      }
    } catch (err: FetchBaseQueryError | SerializedError | any) {
      if (err?.status === 406) {
        const description = err?.data.error.map((e: any) => {
          const keys = Object.keys(e.constraints);
          return keys
            .map((k, i) => {
              return "" + k + ": " + e.constraints[k];
            })
            .join("; ");
        });
        dispatch(
          showNotification({
            message: "Ошибка валидации",
            description,
            type: "error",
          })
        );
      } else if (err?.status === 401) {
        const description = err?.data.error;
        dispatch(
          showNotification({ message: "Ошибка", description, type: "error" })
        );
      } else {
        const description = err?.data.error;
        dispatch(
          showNotification({ message: "Ошибка", description, type: "error" })
        );
      }
    }
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.compactAlgorithm,
        inherit: false,
      }}>
      <Space className="login_page" direction="vertical" size={[0, 48]}>
        <Layout className="login_layout">
          <Row className="login_layout_row">
            <Col
              className="login_layout_image_col"
              flex={"auto"}
              sm={0}
              xs={0}
              md={12}></Col>
            <Col className="login_layout_form_col" sm={24} xs={24} md={12}>
              <Flex
                align="space-between"
                justify="flex-start"
                vertical
                style={{ height: "100%" }}>
                <Flex
                  className="login_form_header"
                  align="center"
                  justify="space-between">
                  <div>
                    <img src="/logo.svg" alt="" />
                  </div>
                  <div className="login_form_header_title">Нейротех</div>
                  <div>
                    <ExclamationCircleOutlined style={{ fontSize: "24px" }} />
                  </div>
                </Flex>
                <Flex
                  justify="center"
                  align="center"
                  vertical
                  style={{ height: "100%" }}>
                    <div className="login_form">
                    <div className="login_form_head">Авторизация</div>
                      <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        autoComplete="on">
                        <Form.Item<FieldType>
                          name="phone_number"
                          rules={[
                            {
                              required: true,
                              message: "Номер телефона",
                            },
                          ]}>
                          <MaskedInput
                            mask={"+00000000000"}
                            placeholder="Номер телефона"
                          />
                        </Form.Item>

                        <Form.Item<FieldType>
                          wrapperCol={{ span: 24 }}
                          name="password"
                          rules={[{ required: true, message: "Пароль" }]}>
                          <Input.Password />
                        </Form.Item>

                        <Form.Item wrapperCol={{ span: 24 }}>
                          <Button block type="primary" htmlType="submit">
                            Войти
                          </Button>
                        </Form.Item>
                      </Form>
                    <div className='tgloginContainer' id="tglogin"></div>
                  </div>
                </Flex>
              </Flex>
            </Col>
          </Row>
        </Layout>
        {isLoading ? (
          <div className="loaderContainer">
            <Spin size="large" />
          </div>
        ) : (
          ""
        )}
      </Space>
    </ConfigProvider>
  );
};

export default Login;