import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconExtend: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)

    return <svg className={classNames.join(' ')} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.91798 1.16798C2.64211 0.443846 3.72569 0.166992 5.12242 0.166992H8.37823C9.77496 0.166992 10.8585 0.443846 11.5827 1.16798C12.3068 1.89211 12.5837 2.97569 12.5837 4.37242V7.62823C12.5837 9.02496 12.3068 10.1085 11.5827 10.8327C10.8585 11.5568 9.77496 11.8337 8.37823 11.8337H5.12242C3.72569 11.8337 2.64211 11.5568 1.91798 10.8327C1.19385 10.1085 0.916992 9.02496 0.916992 7.62823V4.37242C0.916992 2.97569 1.19385 1.89211 1.91798 1.16798ZM2.49353 1.74353C1.99673 2.24033 1.73095 3.05597 1.73095 4.37242V7.62823C1.73095 8.94468 1.99673 9.76032 2.49353 10.2571C2.99033 10.7539 3.80597 11.0197 5.12242 11.0197H8.37823C9.69468 11.0197 10.5103 10.7539 11.0071 10.2571C11.5039 9.76032 11.7697 8.94468 11.7697 7.62823V4.37242C11.7697 3.05597 11.5039 2.24033 11.0071 1.74353C10.5103 1.24673 9.69468 0.980946 8.37823 0.980946H5.12242C3.80597 0.980946 2.99033 1.24673 2.49353 1.74353Z" fill="#EAEAEC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.2939 2.45674C10.4528 2.61567 10.4528 2.87335 10.2939 3.03229L3.78229 9.54391C3.62335 9.70285 3.36567 9.70285 3.20674 9.54391C3.0478 9.38498 3.0478 9.1273 3.20674 8.96836L9.71836 2.45674C9.8773 2.2978 10.135 2.2978 10.2939 2.45674Z" fill="#EAEAEC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.42862 2.74451C7.42862 2.51974 7.61083 2.33753 7.8356 2.33753H10.0061C10.2309 2.33753 10.4131 2.51974 10.4131 2.74451V4.91505C10.4131 5.13982 10.2309 5.32203 10.0061 5.32203C9.78137 5.32203 9.59916 5.13982 9.59916 4.91505V3.15149H7.8356C7.61083 3.15149 7.42862 2.96928 7.42862 2.74451Z" fill="#EAEAEC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.49451 6.67862C3.71928 6.67862 3.90149 6.86083 3.90149 7.0856V8.84916H5.66505C5.88982 8.84916 6.07203 9.03137 6.07203 9.25614C6.07203 9.48091 5.88982 9.66312 5.66505 9.66312H3.49451C3.26974 9.66312 3.08753 9.48091 3.08753 9.25614V7.0856C3.08753 6.86083 3.26974 6.67862 3.49451 6.67862Z" fill="#EAEAEC"/>
</svg>
}
