import { ImageType } from "./Image.type"

export type MjCreateType = {
  input_text: string
  mode?: MidjourneyProcessModeEnum,
  parent_id?: string,

}
export enum MidjourneyActiionEnum {
  reroll = "reroll",
  upscale1 = "upscale1",
  upscale2 = "upscale2",
  upscale3 = "upscale3",
  upscale4 = "upscale4",
  variation1 = "variation1",
  variation2 = "variation2",
  variation3 = "variation3",
  variation4 = "variation4",
  high_variation = "high_variation",
  inpaint = "inpaint",
  low_variation = "low_variation",
  "outpaint_1.5" = "outpaint_1.5",
  outpaint_2x = "outpaint_2x",
  outpaint_custom = "outpaint_custom",
  pan_down = "pan_down",
  pan_left = "pan_left",
  pan_right = "pan_right",
  pan_up = "pan_up",
  upscale_creative = "upscale_creative",
  upscale_subtle = "upscale_subtle",
}

export type MjActionType = {
  parent_id?: string,
  action: string
}

export enum MidjourneyTaskTypeEnum {
  imagine = 'imagine',
  upscale = 'upscale',
  variation = 'variation',
  reroll = 'reroll',
  describe = 'describe',
  seed = 'seed',
  blend= 'blend',
  inpaint='inpaint',
  outpaint='outpaint',
  pan='pan'
}

export enum MidjourneyProcessModeEnum {
  fast = 'fast',
  relax = 'relax',
  turbo = 'turbo'
}

export enum MidjourneyStatusEnum {
  completed = 'completed',
  failed = 'failed',
  pending = 'pending',
  processing = 'processing',
  staged = 'staged',
}

export enum MidjourneyAspectRatioEnum {
  ONE_BY_ONE = '1:1'
}
export enum MidjourneyStatusProjectEnum {
  NOT_CREATED = 'NOT_CREATED',
  PROJECT_CREATED = 'PROJECT_CREATED',
  PROJECT_PROCESS_GENERATE = 'PROJECT_PROCESS_GENERATE',
  LAST_ATTEMPT_GENERATE = 'LAST_ATTEMPT_GENERATE',
  GENERATE_DONE = 'GENERATE_DONE',
  DATA_UPLOADED = 'DATA_UPLOADED',
  ERROR_CREATE_PROJECT = 'ERROR_CREATE_PROJECT',
  ERROR_CREATE_ANSWER = 'ERROR_CREATE_ANSWER',
  SENDED_USER_DATA = 'SENDED_USER_DATA'
}
export type MidjourneyItemType = {
  id: number
  parentId: number,
  created_at: string
  updated_at: string
  text: string|null
  translated_text: string|null
  sorse_url: string|null
  task_type: MidjourneyTaskTypeEnum
  index: string|null
  task_id: string|null
  origin_task_id: string|null
  actions: MidjourneyActiionEnum[]
  process_mode: MidjourneyProcessModeEnum
  aspect_ratio: MidjourneyAspectRatioEnum
  webchat_id: number|null
  project_status: MidjourneyStatusProjectEnum
  zoom_ratio: string|null
  direction: string|null
  error_message: string|null
  comment: string|null
  meta: any|null
  account_id: number
  image: ImageType
  childs: MidjourneyItemType[]
  is_favorite: boolean
}