import { Button } from "antd"
import TextArea from "antd/es/input/TextArea"
import React, { useState } from "react"

import './MidjourneyCreateProps.scss'
import { MidjourneyProcessModeEnum, MjCreateType } from "../../../types/Midjourney.type"
import { useCreateMidjourneyProjectMutation } from "../../../store/midjourneyApiSlice"
import { useNavigate } from "react-router-dom"

type PropsType = {
  id?: string
}

export const MidjourneyCreateProps: React.FC<PropsType> = ({ id }) => {
  const navigate = useNavigate();

  const [mjData, setMjData] = useState<MjCreateType>({
    input_text: '',
    mode: undefined,
    parent_id: id
  })
  const [createMjProject, ] = useCreateMidjourneyProjectMutation()

  const submit = ()=>{
    createMjProject(mjData).then((e: any)=>{
      // navigate('/lk/images/mj/'+e.data.id);
    })
  }

  const canSubmit = () => {
    if(!mjData.input_text || !mjData.mode) return true
    return false
  }

  return <div className="lkMidjourneyCreateProps">
    <div className="lkMidjourneyCreatePropsLabel">Настройки</div>
    <div className="lkMidjourneyCreateProp">
      <div className="lkMidjourneyCreatePropLabel">Опишите изображение</div>
      <div className="lkMidjourneyCreatePropValue">
        <TextArea rows={4} 
            value={mjData.input_text}
            placeholder="Вода — это стихия, которая окружает нас повсюду, являясь основой жизни на Земле."
            onChange={(val) =>
              setMjData({ ...mjData, input_text: val.target.value })
            }
        >

        </TextArea>
      </div>
    
    </div>
    <div className="lkMidjourneyCreateProp">
      <div className="lkMidjourneyCreatePropLabel">Режим работы</div>
      <div className="lkMidjourneyCreatePropValue">

        <Button onClick={()=>{setMjData({ ...mjData, mode: MidjourneyProcessModeEnum.relax })}} 
          type={mjData.mode===MidjourneyProcessModeEnum.relax?'primary':'default'}>Сбалансированный</Button>  
        <Button onClick={()=>{setMjData({ ...mjData, mode: MidjourneyProcessModeEnum.fast })}} 
          type={mjData.mode===MidjourneyProcessModeEnum.fast?'primary':'default'}>Быстрый</Button>  
        <Button onClick={()=>{setMjData({ ...mjData, mode: MidjourneyProcessModeEnum.turbo })}} 
          type={mjData.mode===MidjourneyProcessModeEnum.turbo?'primary':'default'}>Турбо</Button>  
      </div>        
    </div>
    <div className="lkMidjourneyCreateSubmit">
      <Button
      onClick={submit}
        disabled={canSubmit()}
        style={{width: '100%'}}>Подтвердить</Button>
      <div className="lkMidjourneyCreateSubmitLabel">Для генерации необходимо заполнить все поля.</div>
    </div>
  </div>
}