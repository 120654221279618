import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconPlay: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)
    
    return <svg className={classNames.join(' ')} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.75 2.57789V9.42306C0.75 10.825 2.41841 11.7048 3.75 11.0038L7.00065 9.29431L10.2513 7.57765C11.5829 6.87669 11.5829 5.12426 10.2513 4.4233L7.00065 2.70664L3.75 0.997133C2.41841 0.296164 0.75 1.1688 0.75 2.57789Z" fill="#FDFDFD"/>
    </svg>
    
    //   return <svg className={classNames.join(' ')} width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M8.20242 4.59979L1.26659 8.78229C0.858252 9.0273 0.333252 8.73563 0.333252 8.25729V3.59063C0.333252 1.55479 2.53242 0.283127 4.29992 1.29813L8.19659 3.53813C8.59909 3.77729 8.60492 4.36063 8.20242 4.59979Z" fill="#FDFDFD"/>
    //   <path d="M8.55242 8.01813L3.83325 10.7423C2.98742 11.2265 2.03075 11.1273 1.33659 10.6373C0.998252 10.404 1.03909 9.88479 1.39492 9.67479L8.80909 5.22979C9.15909 5.01979 9.61992 5.21813 9.68409 5.62063C9.82992 6.52479 9.45659 7.49896 8.55242 8.01813Z" fill="#FDFDFD"/>
    //   </svg>
      
}

