import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconVaritions: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)

    return <svg className={classNames.join(' ')}  width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.56691 0.542083C7.87526 0.251999 8.30752 0.166992 8.77436 0.166992H10.9666C11.4334 0.166992 11.8657 0.251999 12.1741 0.542083C12.4886 0.838039 12.5837 1.26048 12.5837 1.7135V5.40343C12.5837 5.85645 12.4886 6.27889 12.1741 6.57485C11.8657 6.86493 11.4334 6.94994 10.9666 6.94994H8.77436C8.30752 6.94994 7.87526 6.86493 7.56691 6.57485C7.25232 6.27889 7.1573 5.85645 7.1573 5.40343V1.7135C7.1573 1.26048 7.25232 0.838039 7.56691 0.542083ZM8.12463 1.13492C8.04988 1.20525 7.97126 1.35257 7.97126 1.7135V5.40343C7.97126 5.76435 8.04988 5.91168 8.12463 5.982C8.20563 6.0582 8.3784 6.13598 8.77436 6.13598H10.9666C11.3626 6.13598 11.5353 6.0582 11.6163 5.982C11.6911 5.91168 11.7697 5.76435 11.7697 5.40343V1.7135C11.7697 1.35257 11.6911 1.20525 11.6163 1.13492C11.5353 1.05873 11.3626 0.980946 10.9666 0.980946H8.77436C8.3784 0.980946 8.20563 1.05873 8.12463 1.13492Z" fill="#EAEAEC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.56691 8.13898C7.87526 7.8489 8.30752 7.76389 8.77436 7.76389H10.9666C11.4334 7.76389 11.8657 7.8489 12.1741 8.13898C12.4886 8.43494 12.5837 8.85738 12.5837 9.3104V10.2871C12.5837 10.7402 12.4886 11.1626 12.1741 11.4586C11.8657 11.7487 11.4334 11.8337 10.9666 11.8337H8.77436C8.30752 11.8337 7.87526 11.7487 7.56691 11.4586C7.25232 11.1626 7.1573 10.7402 7.1573 10.2871V9.3104C7.1573 8.85738 7.25232 8.43494 7.56691 8.13898ZM8.12463 8.73182C8.04988 8.80215 7.97126 8.94947 7.97126 9.3104V10.2871C7.97126 10.6481 8.04988 10.7954 8.12463 10.8657C8.20563 10.9419 8.3784 11.0197 8.77436 11.0197H10.9666C11.3626 11.0197 11.5353 10.9419 11.6163 10.8657C11.6911 10.7954 11.7697 10.6481 11.7697 10.2871V9.3104C11.7697 8.94947 11.6911 8.80215 11.6163 8.73182C11.5353 8.65563 11.3626 8.57784 10.9666 8.57784H8.77436C8.3784 8.57784 8.20563 8.65563 8.12463 8.73182Z" fill="#EAEAEC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.88432 6.01865C1.80957 6.08897 1.73095 6.2363 1.73095 6.59722V10.2871C1.73095 10.6481 1.80957 10.7954 1.88432 10.8657C1.96532 10.9419 2.13809 11.0197 2.53405 11.0197H4.72629C5.12225 11.0197 5.29502 10.9419 5.37602 10.8657C5.45077 10.7954 5.5294 10.6481 5.5294 10.2871V6.59722C5.5294 6.2363 5.45077 6.08897 5.37602 6.01865C5.29502 5.94245 5.12225 5.86467 4.72629 5.86467H2.53405C2.13809 5.86467 1.96532 5.94245 1.88432 6.01865ZM1.3266 5.4258C1.63495 5.13572 2.06721 5.05071 2.53405 5.05071H4.72629C5.19313 5.05071 5.62539 5.13572 5.93374 5.4258C6.24833 5.72176 6.34335 6.1442 6.34335 6.59722V10.2871C6.34335 10.7402 6.24833 11.1626 5.93374 11.4586C5.62539 11.7487 5.19313 11.8337 4.72629 11.8337H2.53405C2.06721 11.8337 1.63495 11.7487 1.3266 11.4586C1.01201 11.1626 0.916992 10.7402 0.916992 10.2871V6.59722C0.916992 6.1442 1.01201 5.72176 1.3266 5.4258Z" fill="#EAEAEC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.88432 1.13492C1.80957 1.20525 1.73095 1.35257 1.73095 1.7135V2.69025C1.73095 3.05118 1.80957 3.1985 1.88432 3.26883C1.96532 3.34502 2.13809 3.42281 2.53405 3.42281H4.72629C5.12225 3.42281 5.29502 3.34502 5.37602 3.26883C5.45077 3.1985 5.5294 3.05118 5.5294 2.69025V1.7135C5.5294 1.35257 5.45077 1.20525 5.37602 1.13492C5.29502 1.05873 5.12225 0.980946 4.72629 0.980946H2.53405C2.13809 0.980946 1.96532 1.05873 1.88432 1.13492ZM1.3266 0.542083C1.63495 0.251999 2.06721 0.166992 2.53405 0.166992H4.72629C5.19313 0.166992 5.62539 0.251999 5.93374 0.542083C6.24833 0.838039 6.34335 1.26048 6.34335 1.7135V2.69025C6.34335 3.14327 6.24833 3.56571 5.93374 3.86167C5.62539 4.15175 5.19313 4.23676 4.72629 4.23676H2.53405C2.06721 4.23676 1.63495 4.15175 1.3266 3.86167C1.01201 3.56571 0.916992 3.14327 0.916992 2.69025V1.7135C0.916992 1.26048 1.01201 0.838039 1.3266 0.542083Z" fill="#EAEAEC"/>
</svg>
}
