import React from "react";
import { NeuronetAccount, NeuronetAccountKey, NeuronetAccountKeyFolders, NeuronetAccountKeySecret } from "../../../types/SaveNeuronet.type";
import { Button, Card, Checkbox, Col, Flex, Input, Row } from "antd";
import { AccountKey } from "./AccountEdit/AccountKey";
import { AccountKeySecret } from "./AccountEdit/AccountKeySecret";
import { IconCross } from "../../../Components/Icons/IconCross";
import { AccountKeyFolders } from "./AccountEdit/AccountKeyFolders";
import FormItem from "antd/es/form/FormItem";

type PropsType = {
  accounts: NeuronetAccount[]
  type: string
  onChange: (val: any[]) => void
}

export const NeuronetCredentials: React.FC<PropsType> = ({accounts, type, onChange}) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const addAccount = () => {
    let val: NeuronetAccount
    let val1: NeuronetAccount[] = [...accounts]
    const cred: NeuronetAccountKey = ([
      'CHAT_GPT', 
      'DALL3', 
      'VIDEO_GENERATE_VIZARD', 
      'VIDEO_GENERATE_DID', 
      'MIDJOURNEY', 
      'CLAUDE_SONNET'].includes(type))
      ? { key: ''} as NeuronetAccountKey : { key: '', secret: ''} as NeuronetAccountKeySecret
    val = {
      is_enabled: false,
      use_proxy: false,
      name: '',
      credentials: cred
    }
    val1.push(val)
    onChange(val1)
  }

  const changeAccountName = (val: any, index: number) => {
    let val1: NeuronetAccount[] = accounts.map((account, idx) => {
      if(idx === index ) {
        const newAccount = Object.assign({}, account)
        newAccount.name = val
        return newAccount
      }
      return account
    })
    onChange(val1)
  }

  const changeAccountCredentials = (val: any, index: number) => {
    let val1: NeuronetAccount[] = accounts.map((account, idx) => {
      if(idx === index ) {
        const newAccount = Object.assign({}, account)
        newAccount.credentials = val
        return newAccount
      }
      return account
    })
    onChange(val1)
  }

  const changeAccountUseProxy = (val: any, index: number) => {
    let val1: NeuronetAccount[] = accounts.map((account, idx) => {
      if(idx === index ) {
        const newAccount = Object.assign({}, account)
        newAccount.use_proxy = val
        return newAccount
      }
      return account
    })
    onChange(val1)
  }
  const changeAccountIsEnabled = (val: any, index: number) => {
    let val1: NeuronetAccount[] = accounts.map((account, idx) => {
      if(idx === index ) {
        const newAccount = Object.assign({}, account)
        newAccount.is_enabled = val
        return newAccount
      }
      return account
    })
    onChange(val1)
  }

  const handleDeleteAccount = (index: number) => {
    let val1: NeuronetAccount[] = [...accounts]

    val1.splice(index,1)
    onChange(val1)
  }

  if ([
    'CHAT_GPT', 
    'DALL3', 
    'KANDINSKY31', 
    'GIGACHAT', 
    'VIDEO_GENERATE_VIZARD', 
    'YA_SPEECH_STT', 
    'YA_SPEECH_TTS',
    'VIDEO_GENERATE_DID', 
    'MIDJOURNEY', 
    'CLAUDE_SONNET'].includes(type)) {
    return <>
    <Flex vertical>
      <Flex style={{justifyContent:'space-between', alignItems: 'center'}}>
        <h4>Аккаунты нейросети</h4>
        <Button onClick={addAccount}>Добавить аккаунт</Button>
      </Flex>
      {accounts && accounts.map((account: NeuronetAccount, index) => {
        return <Card>
            <div style={{position: 'absolute', right: '0px', top: '0px'}}>
              <Button onClick={()=>handleDeleteAccount(index)} type="text">
                <IconCross />
              </Button>
            </div>
            <Flex vertical style={{maxHeight: '400px', overflow: 'hidden', overflowY: 'scroll'}}>
            <Row>
            <Col span={24}>
            <FormItem  labelCol= {{xs: { span: 24 },sm: { span: 3 },}}
                wrapperCol =  {{xs: { span: 24 },sm: { span: 21 },}} label={<span>Имя&nbsp;</span>} hasFeedback>
              <Input value={account.name} onChange={(val)=>{changeAccountName(val.target.value, index)}}/>
            </FormItem>
            </Col>
            </Row>
              <Row>
                <Col span={16} offset={3}>
                  <Checkbox checked={account.is_enabled} onChange={(val)=>changeAccountIsEnabled(val.target.checked, index)}>Включено</Checkbox>
                  <Checkbox checked={account.use_proxy} onChange={(val)=>changeAccountUseProxy(val.target.checked, index)}>Использовать прокси</Checkbox>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                { type==='CHAT_GPT' && <AccountKey value={account.credentials as NeuronetAccountKey} onChange={(val)=>changeAccountCredentials(val, index)} /> }
                { type==='DALL3' && <AccountKey value={account.credentials as NeuronetAccountKey} onChange={(val)=>changeAccountCredentials(val, index)} /> }
                { type==='KANDINSKY31' && <AccountKeySecret value={account.credentials as NeuronetAccountKeySecret}  onChange={(val)=>changeAccountCredentials(val, index)} /> }
                { type==='GIGACHAT' && <AccountKeySecret value={account.credentials as NeuronetAccountKeySecret}  onChange={(val)=>changeAccountCredentials(val, index)} /> }
                { type==='VIDEO_GENERATE_VIZARD' && <AccountKey value={account.credentials as NeuronetAccountKey}  onChange={(val)=>changeAccountCredentials(val, index)} /> }
                { type==='VIDEO_GENERATE_DID' && <AccountKey value={account.credentials as NeuronetAccountKey}  onChange={(val)=>changeAccountCredentials(val, index)} />  }
                { type==='YA_SPEECH_STT' && <AccountKeyFolders value={account.credentials as NeuronetAccountKeyFolders}  onChange={(val)=>changeAccountCredentials(val, index)} /> }
                { type==='YA_SPEECH_TTS' && <AccountKeyFolders value={account.credentials as NeuronetAccountKeyFolders}  onChange={(val)=>changeAccountCredentials(val, index)} /> }
                { type==='MIDJOURNEY' && <AccountKey value={account.credentials as NeuronetAccountKey} onChange={(val)=>changeAccountCredentials(val, index)} /> }
                { type==='CLAUDE_SONNET' && <AccountKey value={account.credentials as NeuronetAccountKey} onChange={(val)=>changeAccountCredentials(val, index)} /> }

                </Col>
              </Row>
          </Flex>
          </Card>
      })}
    </Flex>
    </>
  } else {
    return <>{type}</>
  }

}