import { Tabs } from "antd";
import React, { useEffect } from "react";
import { LkImagesChatList } from "./LkImagesChatList";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { LkMidjourneyPage } from "../midjourney/LkMidjourneyPage";
import { GenerationImages } from "./GenerationImages";
import { useSelector } from "react-redux";
import { transformGetCreateChatsOptionsQueryData, useGetCreateChatsOptionsQuery } from "../../../store/webchatApiSlice";
import { RootState } from "../../../store/store";
// import { LKVideoVizardCreateProject } from "./LKVideoVizardCreateProject";
// import { LkVideoDid } from "../did/LkVideoDid";
// import { LKVideoVizardView } from "./LKVideoVizardView";

const TabChildren: React.FC = ()=>{
  const location = useLocation()
  return <Routes> 
      <Route path="mj" element={<LkMidjourneyPage />} />
      <Route path=":neuronet_id/:option_id" element={<GenerationImages />} />
      <Route path=':neuronet_id/:option_id/:chat_id' element={<GenerationImages />} />
      <Route path='*' element={ <LkImagesChatList /> } />
    {/* <Route path="vizard/:id" element={<LKVideoVizardView />} /> */}
    {/* <Route path="did" element={< />} />
    <Route path="did/:id" element={< />} /> */}
  </Routes>
}

export const LKImages: React.FC = ()=>{
  const navigate = useNavigate()
  const params = useParams();
  const location = useLocation()
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );

  useEffect(()=>{
    if (location.pathname==='/lk/images') {
      navigate('/lk/images/mj')
    }
  })

  const { data: createChatData } = useGetCreateChatsOptionsQuery({
    output_type: params.type,
  });

  const dataText = transformGetCreateChatsOptionsQueryData(createChatData, ["image"]);

  const all = dataText?.map((item: any) => {
    let key = `/lk/images/${item.neuronet_id}/${item.option_id}`
    if(item.type==='MIDJOURNEY') {
      key = `/lk/images/mj`
    }

    return {
      key: key,
      label: <span style={{fontWeight: 600}}>{item.name}</span>,
      path: key,
    }
  })||[]

  const handleChange = (e: any)=>{
    
    navigate(e)
  }

  
  return <div>
      <Tabs activeKey={location.pathname} items={all} onChange={handleChange}/>
      <TabChildren />
    </div>
}