import { User } from "../../../types/User.type";
import { UserRoleEnum } from "../../../types/UserRole.enum";
import { IconAudio } from "../../Icons/IconAudio";
import { IconEdit } from "../../Icons/IconEdit";
import { IconGenHistory } from "../../Icons/IconGenHistory";
import { IconImgGenerate } from "../../Icons/IconImgGenerate";
import { IconSpeedtest } from "../../Icons/IconSpeedtest";
import { IconSupport } from "../../Icons/IconSupport";
import { IconVideoPlay } from "../../Icons/IconVideoPlay";

// type MenuItem = Required<MenuProps>['items'][number];


export type MenuItemSrcType = {
  key: string,
  title: string
  path: (string|RegExp)[]
  icon?: any,
  children?: MenuItemSrcType[],
  type?: 'group',
  user?: User,
  roles: UserRoleEnum[]
  show: boolean
}
export const lkMenuItems: MenuItemSrcType[] =[
  {
    key: '/lk',
    title: 'Инструменты', 
    path: ['/lk'],
    icon: IconSpeedtest, 
    roles: [UserRoleEnum.Member],
    show: true
  },
  {
    key: '/lk/text',
    title: 'Работа с текстом', 
    path: ['/lk/text', /\/lk\/text\/\d+\/\d+/],
    icon: IconEdit, 
    roles: [UserRoleEnum.Member],
    show: true
  },
  {
    key: '/lk/images',
    title: 'Генерация изображений', 
    path: ['/lk/images', '/lk/images/mj', /\/lk\/images\/\d+\/\d+/ ],
    icon: IconImgGenerate, 
    roles: [UserRoleEnum.Member],
    show: true
  },
  {
    key: '/lk/audio/synthesis',
    title: 'Генерация аудио', 
    path: ['/lk/audio', '/lk/audio/recognition', '/lk/audio/synthesis'],
    icon: IconAudio, 
    roles: [UserRoleEnum.Member],
    show: true
  },
  // {
  //   key: '/lk/audio/recognition',
  //   title: 'Генерация аудио', 
  //   path: ['/lk/audio'],
  //   icon: IconAudio, 
  //   roles: [UserRoleEnum.Member],
  //   show: false
  // },
  {
    key: '/lk/video/vizard',
    title: 'Работа с видео', 
    path: [/\/lk\/video\/vizard[\/\d]+/, '/lk/video/vizard'],
    icon: IconVideoPlay, 
    roles: [UserRoleEnum.Member],
    show: true
  },
  {
    key: '/lk/history',
    title:'История генераций', 
    path: ['/lk/history'],
    icon: IconGenHistory, 
    roles: [UserRoleEnum.Member],
    show: true
  },
  {
    key: '/lk/support/all',
    title: 'Поддержка', 
    path: ['/lk/support/all', '/lk/support/open', '/lk/support/closed'],
    icon: IconSupport, 
    roles: [UserRoleEnum.Member],
    show: true
  },
  // {
  //   key: '/lk/support/open',
  //   title: 'Поддержка', 
  //   path: ['/lk/support/open'],
  //   icon: IconSupport, 
  //   roles: [UserRoleEnum.Member],
  //   show: false
  // },
  // {
  //   key: '/lk/support/closed',
  //   title: 'Поддержка', 
  //   path: ['/lk/support/closed'],
  //   icon: IconSupport, 
  //   roles: [UserRoleEnum.Member],
  //   show: false
  // },
  {
    key: '/lk/faq/',
    title: 'Справка', 
    path: ['/lk/faq/'],
    icon: IconSupport, 
    roles: [UserRoleEnum.Member],
    show: true
  }
]