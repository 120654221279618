import { apiSlice } from '../api/apiSlice'
import { MidjourneyItemType, MjActionType, MjCreateType } from '../types/Midjourney.type'

export const midjourneyApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createMidjourneyProject: builder.mutation({
      query: (payload: MjCreateType) => ({
        url: '/api/webchat/mj/create',
        body: payload,
        method: 'POST'
      }),
      invalidatesTags: ['MidjourneyItems']
    }),
    
    deleteMidjourneyProject: builder.mutation({
      query: (payload: Partial<MidjourneyItemType>) => ({
        url: '/api/webchat/mj/project/'+payload.id,
        method: 'DELETE'
      }),
      invalidatesTags: ['MidjourneyItems']
    }),
    
    toggleMidjourneyFavoriteProject: builder.mutation({
      query: (payload: Partial<MidjourneyItemType>) => ({
        url: '/api/webchat/mj/favorite/'+payload.id,
        method: 'POST'
      }),
      invalidatesTags: ['MidjourneyItems']
    }),
    

    actionMidjourneyProject: builder.mutation({
      query: (payload: MjActionType) => ({
        url: '/api/webchat/mj/action',
        body: payload,
        method: 'POST'
      }),
      invalidatesTags: ['MidjourneyItems']
    }),
    getMidjourneyProjectsList: builder.query({
      query: params => ({
        url: '/api/webchat/mj/list',
        method: 'GET',
        params
      }),
      providesTags: (result, error, arg) => result ? [{ type: 'MidjourneyItem' as const, id: result.id }, 'MidjourneyItems']
      : ['MidjourneyItems'],
    }),
    getMidjourneyProject: builder.query({
      query: params=>({
        url: '/api/webchat/mj/project/'+params.id,
        method: 'GET'
      }),
      providesTags: (result, error, arg) => result ? [{ type: 'MidjourneyItem' as const, id: result.id }, 'MidjourneyItems']
      : ['MidjourneyItems'],
    })
  })
})

export const { 
  useCreateMidjourneyProjectMutation,
  useActionMidjourneyProjectMutation,
  useDeleteMidjourneyProjectMutation,
  useToggleMidjourneyFavoriteProjectMutation,
  useGetMidjourneyProjectQuery,
  useGetMidjourneyProjectsListQuery
 } = midjourneyApiSlice

export default midjourneyApiSlice.reducer
