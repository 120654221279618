import React from "react";
import { MidjourneyItemType } from "../../../types/Midjourney.type";
import { Button, Tag } from "antd";

import "./MidjourneyDetailView.scss"
import { IconCross } from "../../../Components/Icons/IconCross";
import { IconTimer } from "../../../Components/Icons/IconTimer";

type PropsType = {
  item: MidjourneyItemType
  onClose: ()=>void
}

export const MidjourneyDetailView: React.FC<PropsType> = ({item, onClose})=>{


  const formatDate = (date: any): string=>{
    return Intl.DateTimeFormat('ru-RU', {
      dateStyle: "short"
    }).format(new Date(date))
  }
  return <div className="midjourneyDetailViewContainer">
      <div className="midjourneyDetail">
        <div className="midjourneyDetailLabelContainer">
          <div className="midjourneyDetailLabeltags">
            <span className="midjourneyDetailLabeltag">ID {item.id}</span>
            <span className="midjourneyDetailLabeltag">{item.process_mode}</span>
            <span className="midjourneyDetailLabeltag"><IconTimer />&nbsp;{formatDate(item.created_at)}</span>
          </div>
          <div onClick={onClose}><IconCross /></div>
        </div>
        <div className="midjourneyDetailBody">
          <div className="midjourneyDetailBodyTextContainer">
            {item.text}
          </div>
          <div className="midjourneyDetailBodyImage">
            <img width='400px' src={item?.image?.url} alt={item?.text||"Картинка"} />
          </div>
          

        </div>
      </div>
    </div>
}