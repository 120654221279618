import { Checkbox, Col, Form, Input, Modal, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useState } from "react";
import NeuronetTypeSelector from "../../../Components/common/NeuronetTypeSelector";
import TextArea from "antd/es/input/TextArea";
import { SaveNeuronetOption } from "../../../types/SaveNeuronetOption.type";
import { NeuronetLogoUpload } from "./NeuronetLogoUpload";
import NeuronetOptionAliasSelector from "../../../Components/common/NeuronetOptionAliasSelector";

type PropsType = {
  editedItem: SaveNeuronetOption
  save: (data: SaveNeuronetOption)=>Promise<boolean>
  closeModal: ()=>void
  modalVisible: boolean
}

export const NeuronetOptionEditor: React.FC<PropsType> = ({editedItem, save, closeModal, modalVisible}) => {
  const [editedItemLocal, setEditedItemLocal] = useState<SaveNeuronetOption>(editedItem)
  const ok = ()=>{
    save(editedItemLocal).then((e: boolean)=>{
      if(e) closeModal()
    })
    
  }
  const cancel = ()=>{
    closeModal()
  }
  const onUploadComplete = (image: any) => {
    setEditedItemLocal({...editedItemLocal, image: image})
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  
  return <>
  <Modal   getContainer={()=>{return document.getElementById('modals_container')||document.body}}  
        title="Редактирование опции"
        width={'800px'}
        wrapClassName="vertical-center-modal"
        open={modalVisible}
        onOk={() => ok()}
        onCancel={() => cancel()}>
        <Form>
        <div style={{display: 'flex', justifyContent: 'center', alignItems:'center', paddingTop: '12px', paddingBottom: '8px'}}>
            Лого нейросети
          </div>
          <div style={{display: 'flex', justifyContent: 'center', alignItems:'center', paddingBottom: '18px'}}>
            <NeuronetLogoUpload value={editedItemLocal.image} onUploadComplete={onUploadComplete} />

          </div>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Название&nbsp;
              </span>
            }
            hasFeedback>
            <Input value={editedItemLocal.name} onChange={(val)=>setEditedItemLocal({...editedItemLocal, name: val.target.value})}/>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Порядок сортировки&nbsp;
              </span>
            }
            hasFeedback>
            <Input value={editedItemLocal.order} onChange={(val)=>setEditedItemLocal({...editedItemLocal, order: parseInt(val.target.value)})}/>
          </FormItem>
          {/* <Row>
            <Col sm={6} xs={24}></Col>
            <Col>
                <Checkbox checked={editedItemLocal.enabled} onChange={(val)=>setEditedItemLocal({...editedItemLocal, enabled: val.target.checked})}>Разрешить использование</Checkbox>
            </Col>
          </Row> */}
          <Row>
            <Col sm={6} xs={24}></Col>
            <Col>
                <Checkbox checked={editedItemLocal.visible} onChange={(val)=>setEditedItemLocal({...editedItemLocal, visible: val.target.checked})}>Показывать в списке</Checkbox>
            </Col>
          </Row>
            <br/>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Модель нейросети
              </span>
            }
            hasFeedback>
            <Input value={editedItemLocal.model||''} onChange={(val)=>setEditedItemLocal({...editedItemLocal, model: val.target.value})}/>
          </FormItem>


          <FormItem {...formItemLayout}
            label={
              <span>
                О нейросети&nbsp;
              </span>
            }
            hasFeedback>
            <TextArea rows={4} 
              value={editedItemLocal.about_text} 
              onChange={(val)=>setEditedItemLocal({...editedItemLocal, about_text: val.target.value})} />
          </FormItem>

          <FormItem {...formItemLayout}
            label={
              <span>
                Тип&nbsp;
              </span>
            }
            hasFeedback>
            <NeuronetTypeSelector value={editedItemLocal.type} onChange={(val: any)=>setEditedItemLocal({...editedItemLocal, type: val})} />
          </FormItem>
          
          <FormItem {...formItemLayout}
              label={
                <span>
                  Алиас, уникальное&nbsp;
                </span>
              }
              hasFeedback>
            {/* <Input value={editedItemLocal.alias} onChange={(val: any)=>setEditedItemLocal({...editedItemLocal, alias: val.target.value})} /> */}
            <NeuronetOptionAliasSelector 
              value={editedItemLocal.alias} 
              onChange={(val: any)=>setEditedItemLocal({...editedItemLocal, alias: val})}
             />
          </FormItem>
          <FormItem {...formItemLayout}
              label={
                <span>
                  Промпт&nbsp;
                </span>
              }
              hasFeedback>
            <TextArea rows={4} 
              value={editedItemLocal.prompt} 
              onChange={(val)=>setEditedItemLocal({...editedItemLocal, prompt: val.target.value})} />
          </FormItem>
        </Form>
  </Modal>
  </>
}