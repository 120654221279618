import { apiSlice } from "../api/apiSlice";

export const supportApiSlice = apiSlice
.injectEndpoints({
  endpoints: builder => ({
    getSupportRequests: builder.query({
      query: params => ({
        url: '/api/support/list',
        params
      }),
      providesTags: ['SupportRequests'],
    }),
    getSupportRequestsCounts: builder.query({
      query: params => ({
        url: '/api/support/counts',
        params
      }),
      providesTags: ['SupportRequests'],
    }),
    createRequest: builder.mutation({
      query: params =>({
        url: '/api/support/create',
        method: 'POST',
        body: {
          ...params
        }
      }),
      invalidatesTags: ['SupportRequests']
    }),
    createResponse: builder.mutation({
      query: params =>({
        url: '/api/support/response',
        method: 'POST',
        body: {
          ...params
        }
      }),
      invalidatesTags: ['SupportRequest']
    }),

    editStatusReques: builder.mutation({
      query: params =>({
        url: '/api/support/request/status',
        method: 'POST',
        body: {
          ...params
        }
      }),
      invalidatesTags: ['SupportRequest']
    }),
    getAdminSupportRequest: builder.query({
      query: params => ({
        url: '/api/support/admin/'+params.id,
      }),
      providesTags: ['SupportRequest']
    }),
    getAdminSupportRequests: builder.query({
      query: params => ({
        url: '/api/support/admin/list',
        params
      }),
      providesTags: ['SupportRequests'],
    }),
    getAdminSupportRequestsCounts: builder.query({
      query: params => ({
        url: '/api/support/admin/counts',
        params
      }),
      providesTags: ['SupportRequests'],
    }),
  })
})

export const {
  useCreateRequestMutation,
  useCreateResponseMutation,
  useGetSupportRequestsQuery,
  useGetSupportRequestsCountsQuery,
  useGetAdminSupportRequestQuery,
  useGetAdminSupportRequestsQuery,
  useGetAdminSupportRequestsCountsQuery,
  useEditStatusRequesMutation
} = supportApiSlice