import React, { useState } from "react";
import { useActionMidjourneyProjectMutation, useDeleteMidjourneyProjectMutation, useToggleMidjourneyFavoriteProjectMutation } from "../../../store/midjourneyApiSlice";
import { Button, Dropdown, MenuProps, Popconfirm, Popover, Space, Tooltip } from "antd";

import './MidjourneyProjectItem.scss'
import { MidjourneyActiionEnum, MidjourneyItemType, MidjourneyStatusProjectEnum, MidjourneyTaskTypeEnum } from "../../../types/Midjourney.type";
import { IconCrossRed } from "../../../Components/Icons/IconCrossRed";
import { IconStar } from "../../../Components/Icons/IconStar";
import { IconLinearEye } from "../../../Components/Icons/IconLinearEye";
import { IconExtend } from "../../../Components/Icons/IconExtend";
import { IconVaritions } from "../../../Components/Icons/IconVaritions";
import { IconReplay } from "../../../Components/Icons/IconReplay";
import { IconDownload } from "../../../Components/Icons/IconDownload";
import { Loader } from "../../../Components/common/Loader";
import { LoadingOutlined } from "@ant-design/icons";
import { IconStarSelected } from "../../../Components/Icons/IconStarSelected";
import { DeleteDialog } from "../../../Components/common/DeleteDialog";
import { MidjourneyDetailView } from "./MidjourneyDetailView";
import { MidjourneyVariationTypeDialog } from "./MidjourneyVariationTypeDialog";
import { IconCross } from "../../../Components/Icons/IconCross";
import { menuItems } from "../../../Components/admin/Menu/MenuItems";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

type PropsType = {
  item: MidjourneyItemType
}

export const MidjourneyProjectItem: React.FC<PropsType> = ({item}) =>{
  
  let currentAlgorithm = useSelector((state: RootState) => {
    return state.designMode.currentAlgorithm
  })
  
  const [ createAction ] = useActionMidjourneyProjectMutation()
  const [ deleteProject ] = useDeleteMidjourneyProjectMutation()
  const [ toggleFavorites ] = useToggleMidjourneyFavoriteProjectMutation()

  const [ deleteDialogOpen , setDeleteDialogOpen ] = useState<boolean>(false)
  const [ detailsCardOpen , setDetailsCardOpen ] = useState<boolean>(false)
  const [ typeDialogOpen , setTypeDialogOpen ] = useState<boolean>(false)

  const click = (action: any, id: number) => () => {
    createAction({action: action, parent_id: item.id.toString() })
  }
  
  const openDeleteDialog = () => {
    const savedNotAsk = localStorage.getItem('deleteImmediate')
    if (savedNotAsk==='true') {
      handleDelete()
    } else {
      setDeleteDialogOpen(true)
    }
  }

  const handleDelete = () => {
    deleteProject(item).then(e=>{
      setDeleteDialogOpen(false)
    })
    
  }
  const handleToggleFavorite = () => {
    toggleFavorites({id: item.id})
  }


  const canUseActions = ()=>{
    if(!item.task_id) return false
    if(![MidjourneyStatusProjectEnum.DATA_UPLOADED,
      MidjourneyStatusProjectEnum.SENDED_USER_DATA].includes(item.project_status)) {
      return false
    }
    const itemDate = new Date(item.created_at).getTime()
    const hourago = new Date().getTime() - 3600000
    if(itemDate - hourago < 0) return false
    return true
  }

  const disableViewBtn = () => {
    if(item.image?.url) return false
    // if(![MidjourneyStatusProjectEnum.DATA_UPLOADED, 
    //   MidjourneyStatusProjectEnum.SENDED_USER_DATA].includes(item.project_status)) return true
    
    return true
  }


  const isUpscaleBtnDisabled = (index: string) => {
    if(canUseActions()){
      const existsImage = item.childs?.find(i=>i.index===index)
      if(existsImage) return true
      return false
    }
    return true
  }

  const onDownload = () => {
    if(item?.image?.url){
      const a = document.createElement('a')
      a.href = item?.image?.url
      a.target="_blank"
      a.click()
    }
  }
  
  const handleOpenTypeDialog = ()=>{
    setTypeDialogOpen(true)
  }

  const handleTypeDialogClick =(type: string) => {

    createAction({action: type, parent_id: item.id.toString() })
    setTypeDialogOpen(false)
  }

  const menuItems: MenuProps['items']  = [
    {
      label: 'Похожая',
      key: '1',
      onClick: ()=>{handleTypeDialogClick('subtle')}
    },
    {
      label: 'Креативная',
      key: '2',
      onClick: ()=>{handleTypeDialogClick('creative')}
    },
  ]
  const menuProps = {
    items: menuItems ,
    // onClick: handleMenuClick,
  };
  if (!item) return <>Loading</>

    return <>
    <div className="mjProjectItem">
      {/* {JSON.stringify(data)} */}
      { [
        MidjourneyStatusProjectEnum.NOT_CREATED,
        MidjourneyStatusProjectEnum.PROJECT_CREATED,
        MidjourneyStatusProjectEnum.PROJECT_PROCESS_GENERATE,
        MidjourneyStatusProjectEnum.LAST_ATTEMPT_GENERATE,
        MidjourneyStatusProjectEnum.GENERATE_DONE
      ].includes(item.project_status) &&
        <div className="mjProjectItemImage">
          <LoadingOutlined  style={{ fontSize: '64px' }}  />
        </div>
       }
      {[MidjourneyStatusProjectEnum.DATA_UPLOADED,
              MidjourneyStatusProjectEnum.SENDED_USER_DATA
              ].includes(item.project_status) &&
      
        <div className="mjProjectItemImage">
          <img width={'300px'} src={item?.image?.url}></img>
        </div>
      }
      {(item.project_status===MidjourneyStatusProjectEnum.ERROR_CREATE_PROJECT || 
        item.project_status===MidjourneyStatusProjectEnum.ERROR_CREATE_ANSWER) && 
        <div className="mjProjectItemErrorContainer">
          <div className="mjProjectItemErrorLabel">Ошибка!</div>
          <div className="mjProjectItemErrorText">
            Обновите страницу и  создайте генерацию еще раз
            {/* {item.error_message} */}
          </div>
        </div>
      }

      {[MidjourneyTaskTypeEnum.imagine, MidjourneyTaskTypeEnum.reroll, MidjourneyTaskTypeEnum.variation].includes(item.task_type) && 
        <div className="mjProjectItemButtons">
          {/* {canUseActions() && */}
            <div className="mjProjectItemButtonsLine">
              <Tooltip title='Увеличить изображение 1'>
                <Button disabled={isUpscaleBtnDisabled('1')} className="flexbtn" onClick={click('upscale1', item.id)}>U1</Button>
              </Tooltip>
              <Tooltip title='Увеличить изображение 2'>
                <Button disabled={isUpscaleBtnDisabled('2')} className="flexbtn" onClick={click('upscale2', item.id)}>U2</Button>
              </Tooltip>
              <Tooltip title='Увеличить изображение 3'>
                <Button disabled={isUpscaleBtnDisabled('3')} className="flexbtn" onClick={click('upscale3', item.id)}>U3</Button>
              </Tooltip>
              <Tooltip title='Увеличить изображение 4'>
                <Button disabled={isUpscaleBtnDisabled('4')} className="flexbtn" onClick={click('upscale4', item.id)}>U4</Button>
              </Tooltip>
            </div>
          {/* } */}
          <div className="mjProjectItemButtonsLine">
          <Tooltip title='Добавить в избранное'>
            <Button disabled={disableViewBtn()} className="flexbtn" onClick={handleToggleFavorite}>{item.is_favorite?<IconStarSelected/> : <IconStar />}</Button>
          </Tooltip>
          <Tooltip title='Детальный просмотр'>
            <Button disabled={disableViewBtn()} className="flexbtn" onClick={()=>{setDetailsCardOpen(true)}}><IconLinearEye/></Button>
          </Tooltip>
          <Tooltip title='Удалить изображение'>
            <Button className="flexbtn crossRed" onClick={openDeleteDialog}><IconCrossRed /></Button>
          </Tooltip>
          </div>
        </div>
      }
      {item.task_type===MidjourneyTaskTypeEnum.upscale && 
        <div className="mjProjectItemButtons">
          
            <div className="mjProjectItemButtonsLine">
              <Tooltip title='Увеличить разрешение'>
                <Button disabled={!canUseActions()} className="flexbtn" 
                onClick={()=>handleTypeDialogClick('upscale_creative')}><IconExtend /></Button>

              {/* <Popover style={{width: '200px'}} open={detailsCardOpen}
                title={ <div className="midjourneyVariation">
                  
                  <div className="midjourneyVariationLabel">
                    <div className="midjourneyVariationLabelText">Тип вариации</div>
                    <div className="midjourneyVariationLabelClose" onClick={()=>setDetailsCardOpen(false)}><IconCross /></div>
                  </div>
                  
                  <div className="midjourneyVariationBody">
                      <Button onClick={()=>handleTypeDialogClick('upscale_subtle')} style={{width: '192px'}}>Похожая</Button>
                      <Button onClick={()=>handleTypeDialogClick('upscale_creative')} style={{width: '192px'}}>Креативная</Button>
                  </div>
                  
                </div>} placement="topRight">
                
                <Button 
                  disabled={!canUseActions()} 
                  onClick={()=>{setDetailsCardOpen(true)}} 
                  className="flexbtn" ><IconExtend /></Button>
                
              </Popover> */}
              </Tooltip>
              <Tooltip title='Вариации изображения'>
                {/* <Button disabled={!canUseActions()} className="flexbtn" onClick={click(`variation${item.index}`, item.parentId)}><IconVaritions /></Button> */}
                <Popover style={{width: '200px'}} open={typeDialogOpen}
                title={ <div className={"midjourneyVariation " + currentAlgorithm}>
                  
                  <div className="midjourneyVariationLabel">
                    <div className="midjourneyVariationLabelText">Тип вариации</div>
                    <div className="midjourneyVariationLabelClose" onClick={()=>setTypeDialogOpen(false)}><IconCross /></div>
                  </div>
                  
                  <div className="midjourneyVariationBody">
                      <Button onClick={()=>handleTypeDialogClick('variation_low')} style={{width: '192px'}}>Похожая</Button>
                      <Button onClick={()=>handleTypeDialogClick('variation_high')} style={{width: '192px'}}>Креативная</Button>
                  </div>
                  
                </div>} placement="topRight">
                
                <Button 
                  disabled={!canUseActions()} 
                  onClick={()=>{setTypeDialogOpen(true)}} 
                  className="flexbtn" ><IconVaritions /></Button>
                
              </Popover>
              </Tooltip>
              <Tooltip title='Детальный просмотр'>
                <Button disabled={disableViewBtn()} className="flexbtn" onClick={()=>{setDetailsCardOpen(true)}}><IconLinearEye/></Button>
                {/* <Dropdown menu={menuProps}>
                  <Button className="flexbtn" >
                      <IconLinearEye/>
                  </Button>
                </Dropdown> */}

              </Tooltip>
              <Tooltip title='Повторная генерация'>
                <Button disabled={!canUseActions()} className="flexbtn" onClick={click('reroll', item.id)}><IconReplay/></Button>
              </Tooltip>
            </div>
          
          <div className="mjProjectItemButtonsLine">
          <Tooltip title='Добавить в избранное'>
            <Button disabled={disableViewBtn()}  className="flexbtn" onClick={handleToggleFavorite}>{item.is_favorite?<IconStarSelected/> : <IconStar />}</Button>
            </Tooltip>
            <Tooltip title='Скачать изображение'>
              <Button disabled={disableViewBtn()} className="flexbtn" onClick={onDownload}><IconDownload /></Button>
            </Tooltip>
            <Tooltip title='Удалить изображение'>
              <Button className="flexbtn crossRed" onClick={openDeleteDialog}><IconCrossRed /></Button>
            </Tooltip>
          </div>
        </div>
      }


    </div>
      {deleteDialogOpen && 
        <DeleteDialog onDelete={handleDelete} onClose={()=>setDeleteDialogOpen(false)} />
      }
      {detailsCardOpen && 
        <MidjourneyDetailView item={item} onClose={()=>setDetailsCardOpen(false)}/>
      }
      {/* {typeDialogOpen &&
        <MidjourneyVariationTypeDialog onClick={handleTypeDialogClick} onClose={()=>setTypeDialogOpen(false)}/>
      } */}

        {item?.childs?.map((item: any)=>{
          return <MidjourneyProjectItem item={item} />
          })
        }
    </> 
 
}
