import {
  ExclamationOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Popconfirm, Popover } from "antd";
import React from "react";
import { DidItemType, DidStatusEnum } from "../../../types/Did.type";

import "./DidVideoCard.scss";
import { IconStar } from "../../../Components/Icons/IconStar";
import { IconDownload } from "../../../Components/Icons/IconDownload";
import { IconLinearEye } from "../../../Components/Icons/IconLinearEye";
import { IconCross } from "../../../Components/Icons/IconCross";
import { IconPlay } from "../../../Components/Icons/IconPlay";
import { IconStarSelected } from "../../../Components/Icons/IconStarSelected";
import { useAppDispatch } from "../../../store/store";
import { openVideoPlayer } from "../../../store/videoPlayerSlice";
import {
  useDeleteDidItemMutation,
  useToggleDidFavoriteMutation,
} from "../../../store/didApiSlice";
import { DidVideoPlayer } from "./DidVideoPlayer";

type PropsType = {
  item: DidItemType;
  mode: string;
};

export const DidVideoCard: React.FC<PropsType> = ({ item, mode }) => {
  const dispatch = useAppDispatch();
  const [deleteDidItemMutation] = useDeleteDidItemMutation();
  const [toggleDidFavoriteMutation] = useToggleDidFavoriteMutation();
  const handleOpenModal = () => {
    if (item.result_video?.url) {
      dispatch(
        openVideoPlayer({ url: item.result_video?.url, type: "did", item })
      );
    }
  };

  const togleFavorites = async () => {
    await toggleDidFavoriteMutation({ id: item.id });
  };
  const deleteDidProject = async () => {
    await deleteDidItemMutation({ id: item.id });
  };
  const downloadVideo = () => {
    if (!item.result_video?.url) return;
    window.location.assign(item.result_video?.url);
  };

  return (
    <div className="didVideoCard">
      <div className="didVideoCardContent">
        {[
          DidStatusEnum.NOT_CREATED,
          DidStatusEnum.GENERATE_DONE,
          DidStatusEnum.LAST_ATTEMPT_GENERATE,
          DidStatusEnum.PROJECT_CREATED,
          DidStatusEnum.PROJECT_PROCESS_GENERATE,
        ].includes(item.project_status) && (
          <div className="didSuccessCard">

            <div className="imageLoader">
              <LoadingOutlined style={{ fontSize: "64px", color: "#08c" }} />
            </div>
            <div className="didSuccessCardTitle">{item.text}</div>
          </div>
        )}
        {[DidStatusEnum.VIDEO_UPLOADED, DidStatusEnum.SENDED_USER_DATA].includes(item.project_status) && (
          <div className="didSuccessCard" style={{cursor: 'pointer'}} onClick={handleOpenModal}>
            {item.downloaded_image_url &&
              <div className="didSuccessCardBackground" style={{backgroundImage: `url(${item.downloaded_image_url})`}}></div>
            }
            
            <img src={item.result_thumbnail?.url} alt="" />
            <div className="didSuccessCardPlay">
              <div
                className={
                  "didSuccessCardPlayButton" +
                  (!item.result_video?.url ? " disabled" : "")
                }
                onClick={handleOpenModal}>
                <IconPlay />
              </div>
            </div>
            <div className="didSuccessCardTitle">{item.text}</div>
          </div>
        )}
        {item.project_status === DidStatusEnum.ERROR_CREATE_PROJECT && (
          <div className="didSuccessCard">
            <div className="imageLoader">
              <Popover
                content={item.comment}
                title={"Ошибка генерации изображения"}>
                <ExclamationOutlined
                  style={{ fontSize: "64px", color: "#08c" }}
                />
              </Popover>
            </div>
            <div className="didSuccessCardTitle">{item.text}</div>
          </div>
        )}
        {item.project_status === DidStatusEnum.ERROR_CREATE_ANSWER && (
          <div className="didSuccessCard">
            <div className="imageLoader">
              <Popover
                content={item.comment}
                title={"Ошибка генерации"}>
                <ExclamationOutlined
                  style={{ fontSize: "64px", color: "#08c" }}
                />
              </Popover>
            </div>
            <div className="didSuccessCardTitle">{item.text}</div>
          </div>
        )}
        
      </div>
      <div className="didVideoCardButtons">
        <div className="didVideoCardButtonContainer" onClick={togleFavorites}>
          <span className="didVideoCardButton">
            <div>{item.is_favorite ? <IconStarSelected /> : <IconStar />}</div>
          </span>
        </div>
        <div
          className={
            "didVideoCardButtonContainer" +
            (!item.result_video?.url ? " disabled" : "")
          }
          onClick={downloadVideo}>
          <span className="didVideoCardButton">
            <IconDownload />
          </span>
        </div>
        <div
          className={
            "didVideoCardButtonContainer" +
            (!item.result_video?.url ? " disabled" : "")
          }
          onClick={handleOpenModal}>
          <span className="didVideoCardButton">
            <IconLinearEye />
          </span>
        </div>
        {mode === "user" && (
          <div className="didVideoCardButtonContainer">
            <span className="didVideoCardButton">
              <Popconfirm
                style={{ width: "200px" }}
                title="Удалить видео? Вы уверены?"
                placement="topRight"
                onConfirm={deleteDidProject}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}>
                <div>
                  <IconCross />
                </div>
              </Popconfirm>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
