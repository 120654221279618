import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconCrossRed: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)

    return <svg className={classNames.join(' ')}  width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.66242 1.49542C7.76868 1.3854 7.82748 1.23805 7.82615 1.0851C7.82482 0.932152 7.76347 0.785845 7.65532 0.677691C7.54716 0.569536 7.40086 0.508187 7.24791 0.506858C7.09496 0.505529 6.94761 0.564326 6.83759 0.670585L4.33334 3.17483L1.82909 0.670585C1.71907 0.564326 1.57172 0.505529 1.41877 0.506858C1.26582 0.508187 1.11952 0.569536 1.01136 0.677691C0.903208 0.785845 0.841859 0.932152 0.84053 1.0851C0.839201 1.23805 0.897998 1.3854 1.00426 1.49542L3.50851 3.99967L1.00426 6.50392C0.948542 6.55773 0.904103 6.6221 0.873531 6.69327C0.842959 6.76443 0.826867 6.84098 0.826194 6.91843C0.825521 6.99589 0.84028 7.0727 0.869611 7.14439C0.898941 7.21608 0.942255 7.28121 0.997026 7.33598C1.0518 7.39075 1.11693 7.43407 1.18862 7.4634C1.26031 7.49273 1.33712 7.50749 1.41457 7.50681C1.49203 7.50614 1.56857 7.49005 1.63974 7.45948C1.71091 7.42891 1.77528 7.38447 1.82909 7.32875L4.33334 4.8245L6.83759 7.32875C6.94761 7.43501 7.09496 7.49381 7.24791 7.49248C7.40086 7.49115 7.54716 7.4298 7.65532 7.32165C7.76347 7.21349 7.82482 7.06718 7.82615 6.91424C7.82748 6.76129 7.76868 6.61394 7.66242 6.50392L5.15817 3.99967L7.66242 1.49542Z" fill="#DF7171"/>
</svg>
}

