import { Flex, Pagination} from "antd";
import React, { useEffect, useState } from "react";
import { useGetImagesPreviewQuery } from "../../../store/webchatApiSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import { Loader } from "../../../Components/common/Loader";
import { ChatImageCard } from "../../../Components/ChatImageCard/ChatImageCard";

import "./LastImageGenerations.scss"
import { Empty } from "../../../Components/common/Empty";

type PropsType = {
  neuronet_id?: string|undefined
  option_id?: string|undefined
  chat_id?: string|undefined
}

export const LastImageGenerations: React.FC<PropsType> = ({neuronet_id, option_id, chat_id}) => {
  
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const [ pagination, setPagination] = useState({page: 1, limit: 20})
  
  useEffect(()=>{
    setPagination({page: 1, limit: 20})
  }, [neuronet_id, option_id])

  const { data, isLoading, isError  } = useGetImagesPreviewQuery({
    page: pagination.page,
    limit: pagination.limit,
    neuronet_id: neuronet_id,
    option_id: option_id
  });

  if (isLoading) return <Loader />
  if(isError) return <>error</>
  const [messages, count] = data?data:[[],0]

  if(messages.length===0) {
    return <Empty />
  }

  return (
    <div className={'lastImageGenerations '+currentAlgorithm} style={{ }}>
        <Flex className='chatMessageImageContainer'>
            {messages &&
              messages.map((item: any) => {
                return (
                  <ChatImageCard mode='user' key={item.id} item={item} />
                );
              })}

          </Flex>
          <Pagination onChange={(page)=>{
            setPagination({...pagination,page})
          }} align="end" defaultCurrent={pagination.page} pageSize={pagination.limit} total={count} />
    </div>
  );
};
