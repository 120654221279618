import { apiSlice } from "../api/apiSlice";
import { DidItemType, DidRequestType } from "../types/Did.type";


export const didApiSlice = apiSlice
.enhanceEndpoints({
  addTagTypes: ['DidProjects']
})
  .injectEndpoints({
    endpoints: builder => ({
      getPresenters: builder.query({
        query: () => ({
          url: '/api/webchat/did/presenters',
          method:'GET',
        })
      }),
      getVoices: builder.query({
        query: () => ({
          url: '/api/webchat/did/voices',
          method:'GET',
        })
      }),
      createDidProjectRequest: builder.mutation({
        query: (params: DidRequestType) => ({
          url: '/api/webchat/did/create',
          method: 'POST',
          body: params
        }),
        invalidatesTags: ['DidProjects']
      }),
      getDidProjectsList: builder.query({
        query: params => ({
          url: '/api/webchat/did/list',
          method: 'GET',
          params: params
        }),
        providesTags: ['DidProjects']
      }),
      getDidProject: builder.query({
        query: params => ({
          url: `/api/webchat/did/${params.id}`,
          method: 'GET',
        }),
        providesTags: (result, error, arg) => result ? [{ type: 'DidProject' as const, id: result.id }, 'DidProject']
        : ['DidProject'],
        
      }),
      toggleDidFavorite: builder.mutation<DidItemType, {id: number}>({
        query: params => ({
          url: `/api/webchat/did/favorite/${params.id}`,
          method: 'POST'
        }),
        invalidatesTags:  (result, error, arg) => result ? ['DidProjects', {type: 'DidProject', id: result.id }] : ['DidProjects']
      }),
      deleteDidItem: builder.mutation({
        query: params => ({
          url: `/api/webchat/did/delete/${params.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['DidProjects']
      }),
      // getProject: builder.query<VizardType, {id: any}>({
      //   query: params => ({
      //     url: `/api/webchat/vizard/project/${params.id}`,
      //   }),
      //   providesTags: (result, error, arg) => result ? [{ type: 'Vizard' as const, id: result.id }, 'Vizard']
      //       : ['Vizard'],
      // }),
    })
  })

  export const {useGetPresentersQuery, useGetVoicesQuery, 
    useCreateDidProjectRequestMutation, useGetDidProjectsListQuery,
    useGetDidProjectQuery, useToggleDidFavoriteMutation, useDeleteDidItemMutation } = didApiSlice

  export default didApiSlice.reducer